<template>
  <div>
    <Pane>
      <!-- <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">草稿箱</a-space>
      </template>-->
    </Pane>

    <Plan />
  </div>
</template>

<script>
import Plan from "./components/plan.vue";

export default {
  components: {
    Plan
  }
};
</script>